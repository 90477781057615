@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.css');
.App {
    text-align: center;
    font-family: 'Poppins', sans-serif !important;
    overflow: hidden !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body::-webkit-scrollbar {
    display: none;
}
.bg-wallet {
    background-color: #FCFCFC!important;
}
.btn-warning {
    --bs-btn-color: #ffffff !important;
    --bs-btn-bg: #ff7b00 !important;
    --bs-btn-border-color: #ff7b00 !important;
    --bs-btn-hover-color: #ffffff !important;
    --bs-btn-hover-bg: #f08101 !important;
    --bs-btn-hover-border-color: #f08101;
    --bs-btn-focus-shadow-rgb: 217, 164, 6;
    --bs-btn-active-color: #ffffff !important;
    --bs-btn-active-bg: #ff8a00 !important;
    --bs-btn-active-border-color: #ff8a00 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #ff8a00 !important;
    --bs-btn-disabled-border-color: #ff8a00 !important;
    text-transform: uppercase !important;
    padding: 3px 5px 5px 3px !important;
}
.btn-warning2 {
    --bs-btn-color: #ffffff !important;
    --bs-btn-bg: #ff7b00 !important;
    --bs-btn-border-color: #ff7b00 !important;
    --bs-btn-hover-color: #ffffff !important;
    --bs-btn-hover-bg: #f08101 !important;
    --bs-btn-hover-border-color: #f08101;
    --bs-btn-focus-shadow-rgb: 217, 164, 6;
    --bs-btn-active-color: #ffffff !important;
    --bs-btn-active-bg: #ff8a00 !important;
    --bs-btn-active-border-color: #ff8a00 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #ff8a00 !important;
    --bs-btn-disabled-border-color: #ff8a00 !important;
    text-transform: uppercase !important;
    padding: 8px !important;
}
.col-map{
    display: grid;
    grid-template-columns: 40% 70%;
}
line#SvgjsLine61999 {
    stroke: rgba(224, 224, 224, 0) !important;
    stroke-dasharray: 0;
    stroke-width: 1;
    stroke-linecap: butt;
}
g#SvgjsG62003.apexcharts-xaxis-annotations.apexcharts-hidden-element-shown{
    stroke: rgba(224, 224, 224, 0) !important;
    opacity: 0 !important;

}
.bg-lighter {
    /* background-color: rgb(230, 230, 230) !important; */
    /* background-color: #f8f8f8 !important; */
    /* background-color: rgb(242, 244, 245) !important; */
    background: -webkit-linear-gradient(top,rgba(240, 240, 240), rgba(230, 240, 245));
}
.text-light {
    /* background-color: rgb(230, 230, 230) !important; */
    /* background-color: #f8f8f8 !important; */
    /* background-color: rgb(242, 244, 245) !important; */
    color: -webkit-linear-gradient(top,rgba(240, 240, 240), rgba(230, 240, 245));
}
.img-back{
    height: 200px !important;
    width: 100% !important;
}
.img-back1{
    height: 50% !important;
    width: 50% !important;
}
.rounded-corner{
    border-radius: 0 0 0 50px;
}
.bg-light {
    /* background-color: rgb(242, 242, 242) !important; */
    /* background-color: #f8f8f8 !important; */
    background-color: rgb(242, 244, 245) !important;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    width: 3px !important;
    height: 3px !important;
    padding: 5px !important;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    width: 3px !important;
    height: 3px !important;
    padding: 5px !important;
}
.bg-dark-light{
    background-color: #0000007c;
    color: white;
}
.cards{
    background-image: url("images/card.png");
    background-size: cover !important;
    color: #d5d4d4 !important;
    border-radius: 0px solid black !important;
}
.hover-goal-name:hover{
    color: #f08101 !important;
    font-weight: 700 !important;
    cursor: pointer;
}
.bg-back {
    background-image: url("images/back.png");
    background-size: cover;
}
.bg-back1 {
    background-image: url("images/back1.png");
    background-size: cover;
}
.back-drop{
    background-color: rgba(255,255,255,0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
.apexcharts-legend.apexcharts-align-left.apx-legend-position-right{
    padding: 0% !important;
}
.apexcharts-legend-series{
    margin-bottom: 0% !important;
    margin-top: 0% !important;
}
.progress-sm{
    height: 0.5rem !important;
    color: #282c34 !important;
  }
.modals {
    border-top: 10px solid #ffb85c;
}

.modals-left {
    border-left: 5px solid #ffb85c;
}

.scroll-y {
    overflow-y: auto !important;
    scroll-behavior: smooth !important;
    position: relative !important;
    height: 90vh !important;
    overflow-x: hidden !important;
    scrollbar-color: rgba(255, 255, 255, 0) !important;
    scrollbar-width: none !important;
}
.scroll-x {
    overflow-x: auto !important;
    scroll-behavior: smooth !important;
    position: relative !important;
    width: 100vw !important;
    scrollbar-color: rgba(255, 255, 255, 0) !important;
    scrollbar-width: none !important;
}
.container {
    scroll-snap-type: x mandatory;
    scroll-padding: 50%;
  }
.child {
    scroll-snap-align: center;
  }
.scroll-y2 {
    overflow-y: auto !important;
    scroll-behavior: smooth !important;
    position: relative !important;
    height: 70vh !important;
    scrollbar-color: rgba(255, 255, 255, 0) !important;
    scrollbar-width: none !important;
}
.scroll-y5 {
    overflow-y: auto !important;
    scroll-behavior: smooth !important;
    position: relative !important;
    height: 57vh !important; /* because 60vh overlaps with bottom nav for mobile view*/
    scrollbar-color: rgba(255, 255, 255, 0) !important;
    scrollbar-width: none !important;
}
.margin-goals{
    margin-left: -7px !important;
    /* background-color: #252859 !important; */
}
.scroll-y3 {
    overflow-y: auto !important;
    scroll-behavior: smooth !important;
    position: relative !important;
    height: 40vh !important;
    scrollbar-color: rgba(255, 255, 255, 0) !important;
    scrollbar-width: none !important;
}

.side-bar {
    width: 35% !important;
    padding-top: 5% !important;
    padding-bottom: 5% !important;
    align-items: center !important;
}

.icon-padding {
    padding: 5px;
}

.side-barsy {
    width: 35% !important;
    padding-top: 10% !important;
    padding-bottom: 10% !important;
    align-items: center !important;
}

.my-modal {
    margin-top: 4% !important;
    max-width: 40% !important;
    border-radius: 25% !important;
    padding: 0% !important;
    background: none !important;
}
.modaling{
    padding-right: 0% !important;
    margin-right: 0% !important;
}

[title="twitt"]::before {
    content: '\f099';
    font-family: "Font Awesome 6 brands";
    font-weight: 400;
}

.my-modal1 {
    margin-top: 4% !important;
    max-width: 30% !important;
    border-radius: 25% !important;
    padding: 0% !important;
}
.my-modal-pending-withdraws {
    margin-top: 4% !important;
    max-width: 60% !important;
    border-radius: 25% !important;
    padding: 0% !important;
}
.my-modal- {
    margin-top: 4% !important;
    max-width: 60% !important;
    border-radius: 25% !important;
    padding: 0% !important;
}
.tab-nav {
    cursor: pointer;
}

.account:hover {
    background-color: #252859 !important;
    color: #ffb85c;
    cursor: pointer;
}
.round-top-right{
    border-radius: 0 10px 0 10px;
}
.isActive {
    background-color: #252859 !important;
    color: #ffb85c;
    cursor: pointer;
    border: 1px #ffb85c !important;
}
.bg-lighty{
    background: -webkit-linear-gradient(top, rgb(245, 245, 245),rgba(128, 128, 128, 0));
}
.res-home p {
    font-size: small;
}
.rounded-down {
    border-radius: 0 0 5% 5%;
}

.pic {
    position: fixed;
    z-index: 1;
}

.wide {
    width: 78%;
}

.wider {
    width: 90%;
}

.wide-40 {
    width: 45%;
}

.wide-60 {
    width: 70%;
}

.x-small {
    font-size: x-small !important;
}

.blue-dark {
    background-color: rgb(19, 25, 50) !important;
    color: whitesmoke !important;
    color: #10122d;
}
/* .blue-darks {
    background-color: rgb(19, 25, 50) !important;
    color: whitesmoke !important;
} */

.blue-darks {
    background: -webkit-linear-gradient(right, #161735,#161735ed, #16183670), url("images/Premium\ Photo\ _\ Businessman\ hand\ pointing\ finger\ to\ growth\ success\ finance\ business\ chart\ of\ metaverse\ technology\ financial\ graph\ investment\ diagram\ on\ analysis\ stock\ market\ background\ with\ digital\ economy\ exchange_.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    color: whitesmoke !important;
}
.Credit{
    background: -webkit-linear-gradient(right, #fffffff4, #ffffffb8), url("images/cover_21.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(2, 2, 2) !important;
}
.Venture{
    background: -webkit-linear-gradient(right, #ffffff00, #ffffff00), url("images/card.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(242, 241, 241) !important;
}
.Hellena{
    background: -webkit-linear-gradient(right, #fffffff4, #ffffffb8), url("images/cover_22.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(2, 2, 2) !important;
}
.Absolute{
    background: -webkit-linear-gradient(right, #fffffff4, #ffffffa7), url("images/cover_19.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(2, 2, 2) !important;
}.Cash{
    background: -webkit-linear-gradient(right, #fffffff4, #ffffffb8), url("images/cover_21.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(2, 2, 2) !important;
}
.Company{
    background: -webkit-linear-gradient(right, #fffffff4, #ffffffb8), url("images/cover_21.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(2, 2, 2) !important;
}.Real{
    background: -webkit-linear-gradient(right, #ffffff01, #ffffff00), url("images/card.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(239, 237, 237) !important;
}.Private{
    background: -webkit-linear-gradient(right, #fffffff4, #ffffffb8), url("images/cover_21.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(2, 2, 2) !important;
}.Agriculture{
    background: -webkit-linear-gradient(right, #fffffff4, #ffffffb8), url("images/cover_24.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(2, 2, 2) !important;
}.Commodities{
    background: -webkit-linear-gradient(right, #fffffff4, #ffffffb8), url("images/cover_15.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(2, 2, 2) !important;
}
.peach {
    background-color: #dddbff !important;
    color: #282c34 !important;
}

.top {
    margin-top: 8px;
}

.bg-orange {
    background-color: #ff7b00 !important;
    color: white !important;
}

.grey-text {
    color: rgb(200, 200, 200) !important;
}

.grey-back {
    background-color: #fafafa;
}
.carousel-indicators {
    display: none !important;
}

.activeTab {
    color: #dc7500 !important;
}
.shadows{
    box-shadow: rgba(149, 157, 165, 0.166) 0px 1px 30px;
    /* box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px; */
}
.activeTab1 {
    background-color: #d17001 !important;
    color: #252859;
}


.investment {
    background: -webkit-linear-gradient(top, rgba(223, 223, 223, 0),rgba(240, 240, 240, 0)), url("images/white-grey\ hills.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}
.investment-res {
    background: -webkit-linear-gradient(left, #ff8c10c2,#ff8c1032), url("images/white-grey\ hills.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}
.investments {
    background: -webkit-linear-gradient(right, rgba(233, 233, 233, 0.971),rgba(240, 240, 240, 0.09)), url("images/white-grey\ hills.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}
.investment1 {
    background: -webkit-linear-gradient(left, rgb(255, 255, 255),rgba(255, 255, 255, 0)), url("images/white-grey\ hills.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}
.investments2 {
    background: -webkit-linear-gradient(right, rgba(255, 255, 255, 0.769),rgba(255, 255, 255, 0.09));
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}
.sacco {
    background-image: url("images/Path\ 80.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}

.dollar {
    background-image: url("images/depo.png") !important;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 30%;
}

.light {
    background-color: rgba(255, 255, 255, 0.637) !important;
}

.light-res-home {
    background-color: #fafafa1f;
    cursor: pointer;
}
.light-res-homes {
    background: -webkit-linear-gradient(left,rgba(51, 48, 4, 0.189),rgba(251, 249, 249, 0.605),rgba(46, 37, 2, 0.117));
    cursor: pointer;
}
.light-res-homey {
    background-color: #fafafa12;
    cursor: pointer;
}

.light-res-home:hover {
    background-color: #fafafa0a;
    cursor: pointer;
}

.trans-text {
    color: #fafafa3c;
}

/* .lighter {
    background-image: -webkit-linear-gradient(top, #1d2565ae, #ff353535);
    background-clip: text;
    color: transparent !important;
    cursor: pointer !important;
} gives gradient text */

.lightest {
    background-color: rgba(255, 255, 255, 0.805);
}

.border-active {
    border: 1px soild rgb(255, 144, 18) !important;
}

.rounded-25 {
    border-radius: 15px !important;
}

.active {
    color: #ff7b00 !important;
    cursor: pointer !important;
    font-weight: bolder !important;
}

.active:hover {
    color: rgb(0, 0, 0) !important;
    cursor: pointer !important;
    border-color: rgb(255, 144, 18) !important;
}

.warning {
    color: rgb(255, 144, 18) !important;
    cursor: pointer !important;
    font-size: small !important;
    padding: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    border: 1px solid rgb(255, 144, 18) !important;
    border-color: rgb(255, 144, 18) !important;
    width: 150px !important;
    margin: auto !important;
}
.warning1 {
    color: rgb(255, 144, 18) !important;
    cursor: pointer !important;
    font-size: small !important;
    padding: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    width: 150px !important;
    margin: auto !important;
}
.warning-message {
    color: rgb(255, 144, 18) !important;
    border: 1px solid rgb(255, 144, 18) !important;
    border-color: rgb(255, 144, 18) !important;
}
.avatars{
    border: 1px solid #191632 !important;
    color: #191632 !important;
    width: 50px !important;
    font-weight: light !important;
    height: 50px !important;
}
.avatars2{
    background-color: #ffffff3d !important;
    color: white !important;
    font-weight: light !important;
    width: 40px !important;
    height: 40px !important;
}
.avatars2-dark{
    background-color: #0b0d43fb !important;
    color: rgba(255, 255, 255, 0.755) !important;
    font-weight: light !important;
    width: 40px !important;
    height: 40px !important;
}
.A{
    background-color: #ff7b00 !important;
    color: white !important;
    width: 50px !important;
    height: 50px !important;
}
.B{
    background-color: #ff0099 !important;
    color: white !important;
    width: 50px !important;
    height: 50px !important;
}
.C{
    background-color: #242424 !important;
    color: white !important;
    width: 50px !important;
    height: 50px !important;
}
.avatars1{
    background-color: #fa7101 !important;
    color: white !important;
}
.warning-home {
    color: rgb(255, 144, 18) !important;
    cursor: pointer !important;
    font-size: small !important;
    padding: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    border: 1px solid rgb(255, 144, 18) !important;
    border-color: rgb(255, 144, 18) !important;
    width: 100px !important;
    margin: auto !important;
}
.warning-goals {
    color: rgb(255, 255, 255) !important;
    cursor: pointer !important;
    border: none !important;
    background-color: rgb(255, 144, 18) !important;
    box-shadow: 2px 2px 3px #e6e6e6;
    width:80px !important;
    font-size: small !important;
    padding: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: auto !important;
}
.dark-goals {
    color: rgb(255, 255, 255) !important;
    cursor: pointer !important;
    border: none !important;
    background-color: rgb(19, 19, 19) !important;
    box-shadow: 2px 2px 3px #e6e6e6;
    width:80px !important;
    font-size: small !important;
    padding: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: auto !important;
}
.warning-learn-about {
    color: #ff8a00 !important;
    border: 1px solid #ff8a00 !important;
    border-color: #ff8a00 !important;
}

.status {
    color: #ff8a00 !important;
    background-color: rgba(255, 208, 165, 0.448);
    cursor: pointer;
}
.status:hover {
    color: #ff8a00 !important;
    background-color: rgba(255, 208, 165, 0.774);
    cursor: pointer;
}

.red-status {
    background-color: #ffd7d7;
}
.black-hover{
    color: #000000;
    cursor: pointer;
}
.black-hover:hover{
    color: #9e9d9d;
    cursor: pointer;
}
.apexcharts-tooltip span {
    color: #040404 !important;
}
.bk-warning {
    color: rgb(255, 255, 255) !important;
    cursor: pointer !important;
    border: none !important;
    background-color: #ff8a00 !important;
    box-shadow: 2px 2px 3px #e6e6e6;
    width:max-content !important;
    font-size: small !important;
    padding: 12px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: auto !important;
}
.bk-warning:hover {
    color: rgb(255, 255, 255) !important;
    cursor: pointer !important;
    background-color: #da7400  !important;
}
.bk-warning2 {
    color: rgb(255, 255, 255) !important;
    cursor: pointer !important;
    border: none !important;
    background-color: #ff8a00 !important;
    width:max-content !important;
    font-size: small !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    margin: auto !important;
}
.bk-warnings {
    background: -webkit-linear-gradient(right,#ffffffd7, #ffd8a6, #ffffffd7), url("images/Premium\ Photo\ _\ Businessman\ hand\ pointing\ finger\ to\ growth\ success\ finance\ business\ chart\ of\ metaverse\ technology\ financial\ graph\ investment\ diagram\ on\ analysis\ stock\ market\ background\ with\ digital\ economy\ exchange_.jpeg");
    background-repeat: no-repeat;
    border: none !important;
    background-size: cover;
}

.activer {
    cursor: pointer !important;
    color: #ff8a00 !important;
    font-weight: 600 !important;
    text-align: center !important;
}

.blue-active {
    color: #252859 !important;
}

.dont {
    overflow: auto !important;
}

.avatar {
    margin-top: 30% !important;
    width: 50% !important;
    height: 50% !important;
}

.avatar-name {
    margin-bottom: 30% !important;
}

button {
    width: 50% !important;
    align-items: center !important;
    background-color: wheat;
    border: none !important;
    padding: 2% !important;
}

.blue-light {
    color: #252859;
}

.bg-whiter {
    background: -webkit-radial-gradient(left,rgba(255, 255, 255, 0.794),rgba(255, 255, 255, 0.672)) !important;
    /* box-shadow: 1px 2px 8px rgb(191, 191, 191); */
}
span.apexcharts-legend-text{
    color: #4f4e4e !important;
}
.apexcharts-toolbar{
    display: none !important;
}
.radio-warning{
    background-color: #f7f7f7 !important;
    color: #37383e !important;
    padding: 20px !important;
    border-radius: 10px;
}
.bg-red {
    background-color: rgba(221, 153, 153, 0.153);
}

.PhoneInputCountryIcon {
    width: 12%;
    height: 12%;
    padding: 2%;
}
.apexcharts-legend.apexcharts-align-left.apx-legend-position-right{
    text-align: left !important;
}
.bolder {
    font-weight: 900 !important;
}
.col-2.grey-text.bolder{
    text-align: center !important;
}
/* .font-lighter {
    font-weight: 300 !important;
} */
.font{
    font-weight: 100px !important;
}
.input-group-text{
    background-color: #e8e8e8 !important;
    color: rgb(71, 69, 81) !important;
    border: 1px solid #e8e8e8 !important;
}
.form-{
    height: 70% !important;
}
.orange-shade{
    background-color: #f7a335;
}
/* .TabContent {
    background-color: rgba(255, 255, 255, 0.85);
} */

.wheat {
    background-color: wheat !important;
}
.form-control{
    height: 70% !important;
}
.form-select{
    height: 5% !important;
}
.flexName p {
    padding: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 5px !important;
}
svg#SvgjsSvg41050.apexcharts-svg.apexcharts-zoomable.hovering-zoom{
    color: #000000 !important;
}
.mx-mc{
    margin-right: 10px !important;
}
.mx-ml{
    margin-left: 10px !important;
    margin-right: 1px !important;
}
.top{
    padding-top: 1px !important;
}
.bg-grey{
    background-image: url("images/Grey\ white\ abstract\ background\ geometry\ shine\ and\ layer\ element\ vector\ for\ presentation\ design_\ Suit\ for\ business\,\ corporate\,\ institution\,\ party\,\ festive\,\ seminar\,\ and\ talks.jpeg");
    background-size: cover;
}
.bottom-nav {
    z-index: 1;
    bottom: 0%;
    position: fixed;
    width: 100%;
    background-color: rgb(255, 255, 255) !important;
    margin-right: 0 !important;
}
.fix-top{
    z-index: 1;
    top: 0%;
    padding-top: 8px;
    padding-left: 8px;
    position: fixed;
    width: 100%;

}
.p-bar {
    padding: 0% !important;
}

.progress {
    padding: 0% !important;
}

.alert {
    top: 80%;
    position: fixed;
}

/* input[type='radio']:checked:after {
    width: 13px;
    height: 13px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #f7a335;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #ffffff;
    display: none !important;
} */

.message {
    z-index: 1;
    top: 10%;
}
.top-class{
    margin-top: -20px !important;
}
.bg-orang{
    background-color: #ff8a00 !important;
    color: white;
    font-size: 14px !important;
    padding: 12px 8px !important;
}
.wheat{
    background-color: #da7400;
}
/* .form-check-input:checked {
    background-color: #ff8c00 !important;
    border-color: #ff9d00dd !important;
    font-size: medium !important;
}

.form-control:focus {
    color: #ff8c00 !important;
    background-color: #fff !important;
    border-color: #ffbd716f !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(253, 149, 13, 0.25) !important;
    font-size: medium !important;
} */

.logo {
    width: 20% !important;
    height: 20% !important;
}


/* input:-internal-autofill-selected {
    appearance: menulist-button !important;
    background-image: none !important;
    background-color: -internal-light-dark(rgb(232, 240, 254), rgba(126, 97, 70, 0.4)) !important;
    color: fieldtext !important;
    font-size: medium !important;
} */

.goal-image{
    height: 250px;
}
.bg-new{
    background-image: url("images/_\ \(14\).jpeg");
    background-size: cover;
    background-repeat: no-repeat;
}