.wrapper {
    border: 3px solid blue;
}

.container {
    background-color: rgb(225, 225, 225);
}

.barCompleted {
    background-color: #ffb85c;
}

.label {
    font-size: 20px;
    color: green;
}