@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600&display=swap');
body {
    margin: 0;
    /* font-family:  'Oxygen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    font-family: 'Poppins', sans-serif !important;
    /* overflow: hidden !important; */
    font-weight: 500 !important;
    scroll-behavior: smooth !important;
}


/* online change */

p,
h6,
span,input {
    font-size: 14px !important;
    font-weight: 500 !important;
}
h6.bg-lighter{
    font-size: small !important;
    font-weight: 500 !important;
}
.small{
    font-size: x-small !important;
}
h4 {
    font-size: 15px !important;
    font-weight: 800px !important;
    color: rgb(29, 29, 29) !important;
}
h5 {
    font-size: 13px !important;
}
.mobile{
    font-size: 12px !important;
}
::-webkit-input-placeholder {
    font-size: 12px !important;
}
a{
    text-decoration: none !important;
}
button {
    font-size: 12px !important;
}
.special{
    font-size: 15px !important;
}
.img-head {
    height: 35px;
    width: 35px;
}
.img-head-goal {
    height: 60px;
    width: 60px;
}

.img-back {
    height: 200px;
    width: 200px;
}

::-ms-check label {
    font-size: 12px !important;
}

::-ms-check {
    font-size: 12px !important;
}

:focus {
    font-size: 12px !important;
}

:focus::after {
    font-size: 12px !important;
}

.form-control:focus {
    background-color: #ffb85c12 !important;
    border-color: #ffb85cac !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #ffb85c43 !important;
}
.form-check {
    font-size: 12px !important;
}
.form-select {
    font-size: 12px !important;
}
select {
    font-size: 12px !important;
}
.form-select:focus {
    background-color: #ffb85c12 !important;
    border-color: #ffb85cac !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #ffb85c43 !important;
}
.form-check-input:checked {
    background-color: #ffb85cac !important;
    border-color: #ffb85cac !important;
    box-shadow: 0 0 0 0.25rem #ffb85c43 !important;
}
input:-internal-autofill-selected {
    background-color: none !important;
}
/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */
/* Medium devices (tablets, 768px and up) */
@media (max-width: 991px) {
    p,
h6,
span,input {
    font-size: 11px !important;
}
.bluey{
    color: rgb(15, 15, 32) !important;
    font-weight: 500 !important;
}
}
